<template>
  <div>
    <prismic-embed
      :field="slice.primary.youtube_link"
      class="video-container"
    />
  </div>
</template>

<script>
export default {
  props: ['slice'],
  name: 'text-slice'
}
</script>
